/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/semantic-ui@2.4.2/dist/components/transition.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/modal.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/dimmer.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/popup.min.js
 * - /npm/semantic-ui@2.4.2/dist/components/dropdown.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
